
import { pageQuery } from '~/graphql/queries/page'
import { productQuery } from '~/graphql/queries/product'
import { articleQuery } from '~/graphql/queries/article'
import { recipeQuery } from '~/graphql/queries/recipe'
import { eventQuery } from '~/graphql/queries/event'
import ArticleTop from '@/components/pages/ArticleTop'
import PageTop from '@/components/pages/PageTop'
import EventTop from '@/components/pages/EventTop'
import ProductTop from '@/components/pages/ProductTop'
import RecipeTop from '@/components/pages/RecipeTop'
import ArticleBottom from '@/components/pages/ArticleBottom'
import EventBottom from '@/components/pages/EventBottom'
import ProductBottom from '@/components/pages/ProductBottom'
import RecipeBottom from '@/components/pages/RecipeBottom'
import Modules from '@/components/Modules'
import Footer from '@/components/Footer'
export default {
  components: {
    ArticleTop,
    PageTop,
    EventTop,
    ProductTop,
    RecipeTop,
    ArticleBottom,
    EventBottom,
    ProductBottom,
    RecipeBottom,
    Modules,
    Footer,
  },
  async asyncData({ app, route, error }) {
    let graphQl = pageQuery
    let key = 'pages'

    if (route.name.includes('product')) {
      graphQl = productQuery
      key = 'products'
    }
    if (route.name.includes('page')) {
      graphQl = pageQuery
      key = 'pages'
    }
    if (route.name.includes('recipe')) {
      graphQl = recipeQuery
      key = 'recipes'
    }
    if (route.name.includes('article')) {
      graphQl = articleQuery
      key = 'articles'
    }
    if (route.name.includes('event')) {
      graphQl = eventQuery
      key = 'events'
    }

    const query = {
      query: graphQl,
      variables: {
        slug: route.params.slug || 'index',
        publicationState:
          (route.query.preview &&
            route.query.preview === process.env.strapiPreviewSecret) ||
          process.env.NODE_ENV === 'development'
            ? 'PREVIEW'
            : 'LIVE',
      },
      prefetch: true,
    }

    // For previews
    if (route.query.preview === process.env.strapiPreviewSecret) {
      query.errorPolicy = 'all'
      query.fetchPolicy = 'no-cache'
    }

    const page = await app.apolloProvider.defaultClient.query(query)
    if (page.data[key].data.length > 0) {
      page.data[key].data[0].attributes.id = page.data[key].data[0].id
      return { page: page.data[key].data[0].attributes }
    } else {
      error({ statusCode: 404, message: 'Page not found' })
    }
  },
  head() {
    return {
      title: this.$route.name.includes('index')
        ? 'Copenhagen Distillery'
        : this.title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$route.name.includes('index')
            ? 'Copenhagen Distillery'
            : this.title,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: 1200,
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: 630,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
      ],
    }
  },
  mounted() {
    this.$store.commit('setColormode', 'light')
    setTimeout(() => {
      window.scrollTo(0, 0)
      // this.$colorMode.preference = 'dark'
    }, 10)
  },
  computed: {
    ogImage() {
      let url = this.$store.state.config.seo_image.data.attributes.url
      if (this.page && this.page.seo && this.page.seo.metaImage.data) {
        url = this.page.seo.metaImage.data.attributes.url
      } else if (this.page.hero && this.page.hero.data) {
        url = this.page.hero.data.attributes.url
      } else if (this.page.media && this.page.media.data) {
        if (Array.isArray(this.page.media.data)) {
          url = this.page.media.data[0].attributes.url
        } else {
          url = this.page.media.data.attributes.url
        }
      }
      return url.replace('km2xccxuy', 'km2xccxuy/tr:w-1200,h-630')
    },
    title() {
      let pageTitle = ''
      if (this.page && this.page.seo && this.page.seo.metaTitle) {
        pageTitle = this.page.seo.metaTitle
      } else if (this.page && this.page.title) {
        pageTitle = this.page.title
      }
      return pageTitle + ' — Copenhagen Distillery'
    },
    description() {
      let pageDescription = ''
      if (this.page && this.page.seo && this.page.seo.metaDescription) {
        pageDescription = this.page.seo.metaDescription
      } else if (this.page && this.page.description) {
        pageDescription = this.page.description
      } else if (this.$store.state.config.seo_description) {
        pageDescription = this.$store.state.config.seo_description
      }
      return pageDescription
    },
    hero() {
      return (
        this.page.__typename === 'Article' ||
        this.page.__typename === 'Recipe' ||
        this.page.__typename === 'Product' ||
        this.page.__typename === 'Event' ||
        (this.page.__typename === 'Page' &&
          this.page.hero &&
          this.page.hero.data) ||
        (this.page.__typename === 'Page' &&
          this.page.modules[0] &&
          this.page.modules[0].__typename === 'ComponentModuleSlideshow')
      )
    },
  },
}
