
export default {
  watch: {
    '$store.state.shop.products'() {
      this.initProduct()
    },
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return null
      },
    },
    main: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.product) {
      this.initProduct()
    }
  },
  methods: {
    initProduct() {
      const self = this
      const container = document.createElement('div')
      this.$refs.product.appendChild(container)
      window.ui.createComponent('product', {
        id: this.product.id.split('/').pop(),
        node: container,
        moneyFormat:
          this.$store.state.shop.buybutton.currency +
          ' {{amount_no_decimals_with_comma_separator}}',
        options: {
          product: {
            events: {
              afterInit(component) {
                if (self.main && window && window.dataLayer) {
                  const product = component.viewData
                  window.dataLayer.push({
                    event: 'view_item',
                    currency: self.$store.state.shop.buybutton.currency,
                    value: product.variants[0].priceV2.amount,
                    items: [
                      {
                        item_id: product.variants[0].sku,
                        item_name: product.title,
                      },
                    ],
                  })
                }
              },
              addVariantToCart(component) {
                setTimeout(() => {
                  const button = document.getElementById('checkout-button')
                  if (button) {
                    button.addEventListener(
                      'click',
                      function (event) {
                        if (!self.$store.state.consent) {
                          event.preventDefault()
                          event.stopImmediatePropagation()
                          self.$store.commit('setConsentError', true)
                        }
                      },
                      true
                    )
                  }

                  const checkbox = document.getElementById('ageverification')
                  if (checkbox) {
                    checkbox.addEventListener('change', function (event) {
                      if (checkbox.checked) {
                        self.$store.commit('setConsent', true)
                        self.$store.commit('setConsentError', false)
                      } else {
                        self.$store.commit('setConsent', false)
                      }
                    })
                  }
                }, 1000)

                if (window && window.dataLayer) {
                  const product = component.viewData
                  window.dataLayer.push({
                    event: 'add_to_cart',
                    currency: self.$store.state.shop.buybutton.currency,
                    value: product.variants[0].priceV2.amount,
                    items: [
                      {
                        item_id: product.variants[0].sku,
                        item_name: product.title,
                      },
                    ],
                  })
                }
              },
            },
            iframe: false,
            buttonDestination: 'cart',
            contents: {
              img: false,
              title: false,
              options: false,
              price: false,
            },
            templates: {
              button:
                '<div class="button ' +
                (this.primary ? 'primary ' : '') +
                '{{data.classes.product.button}} {{data.buttonClass}}">' +
                '{{data.buttonText}}' +
                '<span class="arrow"></span></div>',
            },
            text: {
              button: 'Add to cart',
              outOfStock: 'Sold out',
            },
          },
        },
      })
    },
  },
}
